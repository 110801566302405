<template>
    <div class="rt-wraper">
        <h1>Data Storage & Archiving Policy</h1>
        <ol>
            <li>
                <div class="heading">Background</div>
                <ol>
                    <li>AML Complete is a software application developed by IAB for the use of its AML Supervised Members and the IAB Compliance Team in the management or oversight of AML risks and compliance with the Money Laundering & Terrorist Financing Regulations (MLTF) 2017 & 2020.</li>
                    <li>Central to the function of AML Complete is the requirement for users to enter accurate data relevant to the supervised practice including the structure and ownership of the practice, employed and contracted staff members and the services offered by the practice as well as similar details describing the client business(es) served by the practice.</li>
                </ol>
            </li>
            <li>
                <div class="heading">Information use and Storage</div>
                <ol>
                    <li>IAB is a ‘Professional Body Supervisor’ (PBS) Under the MLTF Regulations 2017 and 2020. As such IAB uses information relating to bookkeeping and accountancy practices for the lawful purpose of supervising compliance with the MLTF Regulations.</li>
                    <li>IAB have ensured that AML Complete and its associated network and systems provide secure, industry standard storage for the data or documents entered by users.</li>
                </ol>
            </li>
            <li>
                <div class="heading">User Responsibility for mandatory records</div>
                <ol>
                    <li>AML Complete is not intended nor designed to function as a repository for records storage. Particularly in relation to compliance with Regulation 40 (MLTF Regulations 2017 & 2020 - Requirement to keep records) users must not rely on AML Complete for this purpose and instead ensure that they have access to alternative methods or providers of storage for their practice records.</li>
                </ol>
            </li>
            <li>
                <div class="heading">User Access to copies of stored information</div>
                <ol>
                    <li>AML Complete provides the user with an ‘on demand’ facility to download a copy of the most recent data set entered onto the system.</li>
                    <li>Information stored and used by AML Complete may be updated and changed by the user; only the most recent data set or document version is available for downloading.</li>
                    <li>Following suspension of the account the download facility remains accessible for a period of 365 days thereby providing suspended users the option to download the most recently saved version of their data and documents.</li>
                    <li>The data, and uploaded documents, will be retained by IAB for a period of 365 days following suspension of an account. All the data associated with the suspended account will be deleted following expiration of this period.</li>
                </ol>
            </li>
        </ol>
    </div>
</template>
<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {BLink} from 'bootstrap-vue'
export default {
    components: {
        BLink,
        VuexyLogo
    }
}
</script>
<style lang="scss">
    .rt-wraper{padding:30px 40px;display:flex;flex-direction:column;}
    .rt-wraper .logo{align-self:flex-start;margin-bottom:20px;}
    .rt-wraper .logo svg{height:43px;width:160px;}
    .rt-wraper h1{margin:0 0 20px;color:#222;font-size:24px;font-weight:600;}
    .rt-wraper ol{color:#222;}
    .rt-wraper ol li{color:#222;margin-bottom:15px;font-size:18px;line-height:26px;color:#222;font-weight:600;}
    .rt-wraper ol li ol{color:#222;}
    .rt-wraper ol li ol li{color:#222;margin-bottom:10px;font-size:16px;line-height:26px;font-weight:400;}
    .rt-wraper .btn-download{align-self:flex-start;}
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>